.quoteWrapper {
  height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (min-width: 700px) {
  .quoteWrapper {
    height: 100vh;
  }
}

.quoteWrapper .title {
  max-width: 360px;
}

@media screen and (min-width: 1020px) {
  .quoteWrapper .title {
    max-width: none;
    font-size: 5vw;
  }
  .quoteWrapper .title br {
    display: block;
  }
}

.quoteWrapper p {
  font-size: 28px;
}
