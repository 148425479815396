.bioWrapper {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (min-width: 700px) {
  .bioWrapper {
    padding: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 100vh;
  }
}

.bioWrapper article {
  width: 100%;
  font-size: 24px;
}

@media (min-width: 1000px) {
  .bioWrapper article {
    width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .bioWrapper article div {
    width: 57%;
  }
}

.bioWrapper article p {
  font-size: 1em;
  margin-bottom: 50px;
  width: 100%;
}

@media (min-width: 1200px) {
  .bioWrapper article p {
    font-size: 2.25vw;
    margin-bottom: 50px;
    width: 90%;
  }
  .bioWrapper article p:nth-child(3) {
    margin-left: 50px;
  }
  .bioWrapper article p:nth-child(4) {
    margin-left: 100px;
    font-size: 1.5vw;
  }
}

.bioWrapper aside {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 50px;
}

@media (min-width: 1000px) {
  .bioWrapper aside {
    width: 33%;
    padding-top: 0;
  }
}

.bioWrapper aside picture {
  width: 70vw;
  height: 70vw;
  display: block;
  border-radius: 50%  50% 0 0;
  margin-bottom: 20px;
  background-image: url(https://assets.codepen.io/2191903/kb.jpg);
  background-size: cover;
  background-position: center;
}

@media (min-width: 700px) {
  .bioWrapper aside picture {
    width: 25vw;
    height: 25vw;
  }
}

.bioWrapper aside img {
  width: 100px;
  height: auto;
}

.bioWrapper aside .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.bioWrapper aside .icons img {
  max-width: 40px;
  margin: 10px;
  width: 33%;
  max-height: 56px;
}
