footer {
  position: relative;
  background: #222;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 200px;
  z-index: 99;
}

footer p {
  display: block;
}

footer button {
  position: relative;
  border-radius: 50px;
  background: #000;
  color: #fff;
  font-size: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

footer button .emo {
  padding-left: 10px;
  font-size: 1vw;
}

@media (min-width: 700px) {
  footer button {
    border-radius: 50px;
  }
}
