#work{
    padding-top: 100px;
    @media screen and (min-width: 1020px){
        padding-top: 0;
    }
}
.itemWrapper{
    // background: red;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    @media screen and (min-width: 1020px){
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        padding: 50px 50px 200px;
        margin-bottom: 0;
    }
    .info{
        position: relative;
        padding: 20px;
        text-align: left;
        box-sizing: border-box;
        width: 100%;
        @media screen and (min-width: 1020px){
            width: 30%;
            position: sticky;
            top: 15vh;
            padding-top: 15vh;
        }
        .service{
            padding: 10px;
            border-radius: 20px;
            margin: 10px 10px 10px 0;
            display: inline-block;
            font-weight: bold;
            border: 2px solid;
        }
        .title{
            text-align: left;
            // margin-top: 20px;
            @media screen and (min-width: 1020px){
                font-size: 3vw;
            }
        }
    }
    .images{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px 0;
        width:  100%;
        @media screen and (min-width: 1020px){
            width: 70%;
            position: relative;
            right: -50px;
            top: 0;
        }
        img{
            width: 40%;
            margin-bottom: 20px;
            height: auto;
            position: relative;
            box-shadow: 15px 15px 50px #adb5bd75;
            border-radius: 6px;
            @media screen and(min-width: 720px){
                border-radius: 12px;
            }
        }
        video{
            max-width: 95%;
            border-radius: 12px;
            // box-shadow: 15px 15px 50px #adb5bd75;
            box-shadow: 0px 8px 32px -12px black;
            margin-top: 10px;
            @media screen and(min-width: 720px){
                pointer-events: none;
                margin-top: 0;
            }
        }
    }
    .visitLink{
        text-decoration: none;
        font-weight: bold;
        color: #000;
        display: flex;
        align-items: center;
        svg{
            font-size: 25px;
            position: relative;
            opacity: 0.8;
            padding-left: 5px;
            transition: .25s all ease-in-out;
            animation: moveArrow infinite 1s;
        }
        &:hover{
            svg{
                animation: moveArrow reverse 5s;
                opacity: 1;
                // padding-left: 7px;
            }
        }
    }
    &.PrintShopBuddy{
        .images{
            img{
                width: 45%;
                &:nth-child(1){
                    
                }
                &:nth-child(2){
                    width: 38%;
                    margin-top: 150px;
                }
                &:nth-child(3){
                    width: 40%;
                }
                &:nth-child(4){
                    width: 32%;
                    margin-top: 70px;
                }   
                @media screen and(min-width: 720px){
                    width: 22% !important;
                    margin-top: 0 !important;
                }
            }
        }
    }
    &.HIF{
        // background: teal;
        .images{
            @media screen and (min-width: 1020px){
                justify-content: start;
                padding-top: 100px;
            }
            video{
                @media screen and (min-width: 1020px){
                    &:nth-child(1){
                        position: absolute;
                        width: 24%;
                        right: 30px;
                    }
                    &:nth-child(2){
                        width: 74%;
                        right: 0;
                        // padding-bottom: 15%;
                    }
                }

            }
        }
    }
    &.Jefferson{
        .images{
            video{
                width: 35%;
            }
            img{
                &:nth-child(2){
                    width: 30%;
                }
                &:nth-child(3){
                    width: 25%;
                }                
            }
        } 
    }
    &.RiverHouse11{
        .images{
            img{
                &:nth-child(1){
                    width: 52%;
                }
                &:nth-child(2){
                    width: 25%;
                }  
                &:nth-child(3){
                    width: 30%;
                }   
                &:nth-child(4){
                    width: 58%;
                }                 
            }
        } 
    }
    &.Legend{
        .images{
            img{
                &:nth-child(1){
                    width: 50%;
                }
                &:nth-child(2){
                    width: 45%;
                }                
            }
        } 
    }
    &.BrandHorse{
        .images{
            img{
                width: 45%;
                @media screen and(min-width: 720px){
                    width: 22%;
                    &:nth-child(3),&:nth-child(4){
                        margin-top: 20px;
                    }
                }
            }
        } 
    }
    &.SharePoint{
        .images{
            img{
                width: 45%;
                border-radius: 6px;
                @media screen and(min-width: 720px){
                    width: 22%;
                }          
            }
        } 
    }

}

//2Hopkins
.itemWrapper:nth-child(6){
    .images{
        img{
            width: 100%;
            max-width: 95%;
            @media screen and(min-width: 720px){
                max-width: max-content;
            }
        }
    }
}

@keyframes moveArrow {
    0% {left: 0;}
    50% {left: 10px;}
    100% {left: 0;}
  }