.quoteWrapper{
    // background: red;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 700px){
        height: 100vh;
    }
    .title{
        max-width: 360px;
        br{
            // display: none;
        }
        @media screen and (min-width: 1020px){
            max-width: none;
            font-size: 5vw;
            br{
                display: block;
            }
        }
        &:hover{
            // border: 1px solid blue;
        }
    }
    p{
        font-size: 28px;
    }
}