.introWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow-x: hidden;
  overflow-y: visible;
  min-height: 70vh;
}

@media screen and (min-width: 1020px) {
  .introWrapper {
    height: 100vh;
  }
}

.introWrapper article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 3vw 20vw;
  border-radius: 60%;
  background: #f5f5f569;
  z-index: 99;
  margin-top: 10vh;
}

@media screen and (min-width: 1020px) {
  .introWrapper article {
    height: 100vh;
    margin-top: 0;
    padding: 3vw 19vw;
  }
}

.introWrapper article .intro {
  font-size: 16px;
}

@media screen and (min-width: 720px) {
  .introWrapper article .intro {
    font-size: 20px;
  }
}

.introWrapper article .title {
  position: relatiive;
  z-index: 9;
  font-size: 14vw;
}

.introWrapper article .title br {
  display: none;
}

@media screen and (min-width: 720px) {
  .introWrapper article .title {
    font-size: 5vw;
  }
  .introWrapper article .title br {
    display: block;
  }
}

.introWrapper article .copy {
  display: none;
}

.introWrapper .bg {
  position: fixed;
  top: -150vh;
  left: -50vw;
  width: 200vw;
  height: 200vw;
  opacity: 0;
  background: white;
  border-radius: 50%;
  -webkit-animation: move 40s ease infinite alternate;
          animation: move 40s ease infinite alternate;
  -webkit-transition: .5s opacity ease-in-out;
  transition: .5s opacity ease-in-out;
  -webkit-transition: .5s animation ease-in-out;
  transition: .5s animation ease-in-out;
  z-index: -1;
  pointer-events: none;
}

.introWrapper .bg2 {
  position: fixed;
  bottom: 0;
  right: -50vw;
  width: 100vw;
  height: 100vw;
  border-radius: 50%;
  opacity: 0;
  background: white;
  -webkit-animation: move 30s ease infinite alternate;
          animation: move 30s ease infinite alternate;
  -webkit-transition: .5s opacity ease-in-out;
  transition: .5s opacity ease-in-out;
  -webkit-transition: .5s animation ease-in-out;
  transition: .5s animation ease-in-out;
  z-index: -1;
  pointer-events: none;
}

.i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
}

.i-left {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.i-right {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.i-left-wrapper {
  padding: 50px;
}

.i-intro {
  font-size: 30px;
  font-weight: 300;
}

.i-name {
  font-size: 60px;
}

.i-title {
  height: 50px;
  overflow: hidden;
}

.i-title-wrapper {
  height: 100%;
  -webkit-animation: move 20s ease-in-out infinite alternate;
          animation: move 20s ease-in-out infinite alternate;
}

@-webkit-keyframes move {
  20% {
    opacity: 1;
    background: radial-gradient(circle, #1866af 1%, rgba(148, 187, 233, 0) 44%);
  }
  45% {
    opacity: 0;
    background: #ff0070;
    background: radial-gradient(circle, #ff0070 1%, rgba(148, 187, 233, 0) 44%);
  }
  50% {
    -webkit-transition: .25s all ease;
    transition: .25s all ease;
    opacity: 1;
    background: #ff0070;
    background: radial-gradient(circle, #ff0070 1%, rgba(148, 187, 233, 0) 44%);
  }
  75% {
    background: radial-gradient(circle, #1866af 1%, rgba(148, 187, 233, 0) 44%);
    opacity: 1;
  }
  100% {
    background: radial-gradient(circle, #181baf 1%, rgba(148, 187, 233, 0) 44%);
    opacity: .5;
  }
}

@keyframes move {
  20% {
    opacity: 1;
    background: radial-gradient(circle, #1866af 1%, rgba(148, 187, 233, 0) 44%);
  }
  45% {
    opacity: 0;
    background: #ff0070;
    background: radial-gradient(circle, #ff0070 1%, rgba(148, 187, 233, 0) 44%);
  }
  50% {
    -webkit-transition: .25s all ease;
    transition: .25s all ease;
    opacity: 1;
    background: #ff0070;
    background: radial-gradient(circle, #ff0070 1%, rgba(148, 187, 233, 0) 44%);
  }
  75% {
    background: radial-gradient(circle, #1866af 1%, rgba(148, 187, 233, 0) 44%);
    opacity: 1;
  }
  100% {
    background: radial-gradient(circle, #181baf 1%, rgba(148, 187, 233, 0) 44%);
    opacity: .5;
  }
}

.i-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  /* color: red; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
