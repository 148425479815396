


.pensWrapper{
    // width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // padding: 50px;
    h2{
        padding-left: 20px;
    }
    .swiper-slide:nth-of-type(1) {
        margin-left: 20px;
    }
    .swiper-slide:last-of-type {
        margin-right: 50px;
    }
    .pen{
        // width: 25%;
        // background: #fff;
        // box-shadow: 0 20px 60px 0 rgba(0,0,0,.15);
        // padding-bottom: 50px;
        padding-bottom: 50px;
        a{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-shadow: 0px 8px 32px -12px rgba(0, 0, 0, 24%);
            border-radius: 12px;
            position: relative;
            overflow: hidden;
            margin: 20px 0;

            .image{
                display: block;
                width: 100%;
                height: calc(80vw - 90px);
                background-size: cover;
                background-position: top center;
                @media screen and (min-width: 1020px){
                    height: calc(25vw - 90px);


                }

            }
            .over{
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.5);
                color: #fff;
                font-family: 'Shrikhand', cursive;
                font-size: 26px;
                text-align: center;
                pointer-events: none;
                opacity: 0;
                transition: .25s all ease-in-out;
            }
            &:hover{
                .over{
                    opacity: 1;
                    pointer-events: auto;
                    background: rgba(0,0,0,0.75);

                }
            }
        }
    }
}