.title {
  font-family: 'Shrikhand', cursive;
  margin: 0;
  text-align: center;
  font-size: 32px;
  line-height: 1.2;
}

@media screen and (min-width: 700px) {
  .title {
    font-size: 50px;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
