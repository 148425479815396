footer{
    position: relative;
    background: #222;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    z-index: 99;
    p{
        display: block;
    }
    button{
        position: relative;
        border-radius: 50px;
        background: #000;
        color: #fff;
        font-size: 1em;
        display: flex;
        align-items: center;
        .emo{
            padding-left: 10px;
            font-size: 1vw;
        }
        @media (min-width: 700px){
            border-radius: 50px;
        }
    }
}