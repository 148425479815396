.bioWrapper{
    padding: 20px;
    display: flex;
    flex-direction: column; 
    @media screen and (min-width: 700px){
        padding: 50px;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;    
        height: 100vh;
    }
    article{
        width: 100%;
        font-size: 24px;
        @media (min-width: 1000px){
            width: 60%;
            // background: red;
            display: flex;
            justify-content: space-between;
            div{
                width: 57%;
            }
        }
        p{
            font-size: 1em;
            margin-bottom: 50px;
            width: 100%;
            @media (min-width: 1200px){
                font-size: 2.25vw;
                margin-bottom: 50px;
                width: 90%;
                &:nth-child(3){
                    margin-left: 50px;
                }
                &:nth-child(4){
                    margin-left: 100px;
                    font-size: 1.5vw;
                }
            }
        }
    }

    aside{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        @media (min-width: 1000px){
            width: 33%;
            padding-top: 0;
        }
        picture{
            width: 70vw;
            height: 70vw;
            display: block;
            border-radius: 50%  50% 0 0;
            margin-bottom: 20px;
            background-image: url(https://assets.codepen.io/2191903/kb.jpg);
            background-size: cover;
            background-position: center;
            @media (min-width: 700px){
                width: 25vw;
                height: 25vw;                
            }            
        }
        img{
            width: 100px;
            height: auto;
        }
        .icons{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            // max-width: 350px;
            align-items: center;
            justify-content: center;
            @media (min-width: 700px){
                // width: 70%;
                // max-width: 350px;             
            } 
            img{
                max-width: 40px;
                margin: 10px;
                width: 33%;
                max-height: 56px;
            }
        }
    }
    .title{
        
    }
    p{
    //  font-size: 50px;
    }
}