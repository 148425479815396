.contactWrapper{
    display:  flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    padding: 20px;
    @media screen and (min-width: 1020px){
        height: 100vh;
        padding: 0;
    }
    .title{
        

    }
    form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
        max-width: 700px;
        input{
            border: 2px solid #000;
            width: 100%;
            height: 40px;
            margin-bottom: 10px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;            
            @media screen and (min-width: 700px){
                width: calc(50% - 10px);
            }
        }
        textarea{
            width: 100%;
            min-height: 100px;
            border: 2px solid #000;
            outline: none;
            padding: 10px;
            // @media screen (min-width: 700px){
                


            // }
        }
        button{
            background: #000;
            color: #fff;
            border: none;
            margin: 20px 0;
            margin-right: 20px;
            font-size: 1vw;
            font-weight: bold;
        }
    }
}