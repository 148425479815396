.pensWrapper h2 {
  padding-left: 20px;
}

.pensWrapper .swiper-slide:nth-of-type(1) {
  margin-left: 20px;
}

.pensWrapper .swiper-slide:last-of-type {
  margin-right: 50px;
}

.pensWrapper .pen {
  padding-bottom: 50px;
}

.pensWrapper .pen a {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: 0px 8px 32px -12px black;
          box-shadow: 0px 8px 32px -12px black;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}

.pensWrapper .pen a .image {
  display: block;
  width: 100%;
  height: calc(80vw - 90px);
  background-size: cover;
  background-position: top center;
}

@media screen and (min-width: 1020px) {
  .pensWrapper .pen a .image {
    height: calc(25vw - 90px);
  }
}

.pensWrapper .pen a .over {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: 'Shrikhand', cursive;
  font-size: 26px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: .25s all ease-in-out;
  transition: .25s all ease-in-out;
}

.pensWrapper .pen a:hover .over {
  opacity: 1;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.75);
}
