.title{
  font-family: 'Shrikhand', cursive;
  margin: 0;
  text-align: center;
  font-size: 32px;
  line-height: 1.2;
  @media screen and (min-width: 700px){
    font-size: 50px;
  }
}

.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}






