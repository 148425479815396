#work {
  padding-top: 100px;
}

@media screen and (min-width: 1020px) {
  #work {
    padding-top: 0;
  }
}

.itemWrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 80px;
}

@media screen and (min-width: 1020px) {
  .itemWrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    padding: 50px 50px 200px;
    margin-bottom: 0;
  }
}

.itemWrapper .info {
  position: relative;
  padding: 20px;
  text-align: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}

@media screen and (min-width: 1020px) {
  .itemWrapper .info {
    width: 30%;
    position: -webkit-sticky;
    position: sticky;
    top: 15vh;
    padding-top: 15vh;
  }
}

.itemWrapper .info .service {
  padding: 10px;
  border-radius: 20px;
  margin: 10px 10px 10px 0;
  display: inline-block;
  font-weight: bold;
  border: 2px solid;
}

.itemWrapper .info .title {
  text-align: left;
}

@media screen and (min-width: 1020px) {
  .itemWrapper .info .title {
    font-size: 3vw;
  }
}

.itemWrapper .images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 10px 0;
  width: 100%;
}

@media screen and (min-width: 1020px) {
  .itemWrapper .images {
    width: 70%;
    position: relative;
    right: -50px;
    top: 0;
  }
}

.itemWrapper .images img {
  width: 40%;
  margin-bottom: 20px;
  height: auto;
  position: relative;
  -webkit-box-shadow: 15px 15px 50px #adb5bd75;
          box-shadow: 15px 15px 50px #adb5bd75;
  border-radius: 6px;
}

@media screen and (min-width: 720px) {
  .itemWrapper .images img {
    border-radius: 12px;
  }
}

.itemWrapper .images video {
  max-width: 95%;
  border-radius: 12px;
  -webkit-box-shadow: 0px 8px 32px -12px black;
          box-shadow: 0px 8px 32px -12px black;
  margin-top: 10px;
}

@media screen and (min-width: 720px) {
  .itemWrapper .images video {
    pointer-events: none;
    margin-top: 0;
  }
}

.itemWrapper .visitLink {
  text-decoration: none;
  font-weight: bold;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.itemWrapper .visitLink svg {
  font-size: 25px;
  position: relative;
  opacity: 0.8;
  padding-left: 5px;
  -webkit-transition: .25s all ease-in-out;
  transition: .25s all ease-in-out;
  -webkit-animation: moveArrow infinite 1s;
          animation: moveArrow infinite 1s;
}

.itemWrapper .visitLink:hover svg {
  animation: moveArrow reverse 5s;
  opacity: 1;
}

.itemWrapper.PrintShopBuddy .images img {
  width: 45%;
}

.itemWrapper.PrintShopBuddy .images img:nth-child(2) {
  width: 38%;
  margin-top: 150px;
}

.itemWrapper.PrintShopBuddy .images img:nth-child(3) {
  width: 40%;
}

.itemWrapper.PrintShopBuddy .images img:nth-child(4) {
  width: 32%;
  margin-top: 70px;
}

@media screen and (min-width: 720px) {
  .itemWrapper.PrintShopBuddy .images img {
    width: 22% !important;
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 1020px) {
  .itemWrapper.HIF .images {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    padding-top: 100px;
  }
}

@media screen and (min-width: 1020px) {
  .itemWrapper.HIF .images video:nth-child(1) {
    position: absolute;
    width: 24%;
    right: 30px;
  }
  .itemWrapper.HIF .images video:nth-child(2) {
    width: 74%;
    right: 0;
  }
}

.itemWrapper.Jefferson .images video {
  width: 35%;
}

.itemWrapper.Jefferson .images img:nth-child(2) {
  width: 30%;
}

.itemWrapper.Jefferson .images img:nth-child(3) {
  width: 25%;
}

.itemWrapper.RiverHouse11 .images img:nth-child(1) {
  width: 52%;
}

.itemWrapper.RiverHouse11 .images img:nth-child(2) {
  width: 25%;
}

.itemWrapper.RiverHouse11 .images img:nth-child(3) {
  width: 30%;
}

.itemWrapper.RiverHouse11 .images img:nth-child(4) {
  width: 58%;
}

.itemWrapper.Legend .images img:nth-child(1) {
  width: 50%;
}

.itemWrapper.Legend .images img:nth-child(2) {
  width: 45%;
}

.itemWrapper.BrandHorse .images img {
  width: 45%;
}

@media screen and (min-width: 720px) {
  .itemWrapper.BrandHorse .images img {
    width: 22%;
  }
  .itemWrapper.BrandHorse .images img:nth-child(3), .itemWrapper.BrandHorse .images img:nth-child(4) {
    margin-top: 20px;
  }
}

.itemWrapper.SharePoint .images img {
  width: 45%;
  border-radius: 6px;
}

@media screen and (min-width: 720px) {
  .itemWrapper.SharePoint .images img {
    width: 22%;
  }
}

.itemWrapper:nth-child(6) .images img {
  width: 100%;
  max-width: 95%;
}

@media screen and (min-width: 720px) {
  .itemWrapper:nth-child(6) .images img {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
  }
}

@-webkit-keyframes moveArrow {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

@keyframes moveArrow {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}
