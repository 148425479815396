.header {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 999;
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0);
  -webkit-transition: .25s all ease-in-out;
  transition: .25s all ease-in-out;
}

@media screen and (min-width: 480px) {
  .header {
    padding: 10px 50px;
  }
}

.header.active {
  background: rgba(255, 255, 255, 0.5);
}

.header menu {
  position: absolute;
  top: 0;
  right: 30px;
  height: 60vh;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  pointer-events: none;
}

@media screen and (min-width: 480px) {
  .header menu {
    opacity: 1;
    height: 100%;
    pointer-events: auto;
  }
}

.header menu a {
  margin: 0 10px;
  padding: 0;
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.header menu.active {
  position: fixed;
  right: 0;
  top: 79px;
  background: red;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header menu.activeMobile {
  opacity: 1;
  pointer-events: auto;
  height: 60vh;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 0;
  bottom: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.97);
  -webkit-backdrop-filter: blur(12px);
}

.header menu.activeMobile a {
  font-size: 8vw;
  margin: 10px 0;
}

.header menu.activeMobile button {
  margin-top: 20px;
}

.header .ham i {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 6vw;
  cursor: pointer;
}

@media screen and (min-width: 480px) {
  .header .ham i {
    display: none;
  }
}

.header .ham i.close {
  color: red;
  opacity: 0;
  pointer-events: none;
}

.header .ham.active i.open {
  opacity: 0;
  pointer-events: none;
}

.header .ham.active i.close {
  opacity: 1;
  pointer-events: auto;
}

button {
  padding: 10px 20px;
  border-radius: 21px;
  background: #fff;
  font-weight: bold;
  cursor: pointer;
  border: solid 2px #000;
  -webkit-transition: .25s all ease-in-out;
  transition: .25s all ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1);
}

button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.subTitle {
  font-size: 1.2em;
}

.emo {
  font-size: 25px;
}

@media screen and (min-width: 1900px) {
  .emo {
    font-size: 1vw;
  }
}
