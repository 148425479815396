.header{
    // background: pink;
    position: fixed;
    display: flex;
    width: 100vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-sizing: border-box;
    z-index: 999;
    backdrop-filter: blur(12px);
    background: rgba(255,255,255,0);
    transition: .25s all ease-in-out;
    @media screen and(min-width: 480px){
        padding: 10px 50px;
    }
    &.active{
        background: rgba(255,255,255,0.5);
    }

    menu{
        position: absolute;
        top: 0;
        right: 30px;
        height: 60vh;
        margin: 0;
        display: flex;
        align-items: center;
        opacity: 0;
        pointer-events: none;
        // transition: .1s opacity ease-in-out;
        @media screen and (min-width: 480px){
            opacity: 1;
            height: 100%;
            pointer-events: auto;
        }
        a{
            margin: 0 10px;
            padding: 0;
            text-decoration: none;
            color: #000;
            font-weight: bold;
        }
        &.active{
            position: fixed;
            right: 0;
            top: 79px;
            background: red;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        // mobile menu activated
        &.activeMobile{
            opacity: 1;
            pointer-events: auto;
            height: 60vh;
            position: fixed;
            width: 100%;
            top: 0;
            right: 0;
            z-index: 0;
            bottom: 0;
            flex-direction: column;
            padding: 0;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(12px);
            background: rgba(255, 255, 255, .97);
            -webkit-backdrop-filter: blur(12px);
            a{
                font-size: 8vw;
                margin: 10px 0;
            }
            button{
                margin-top: 20px;
            }

        }
    }
    
    .ham{
        i{
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 100%;
            // background:red;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 6vw;
            cursor: pointer;
            @media screen and(min-width: 480px){
                display: none;
            }
            &.open{
                
            }
            &.close{
                color: red;
                opacity: 0;
                pointer-events: none;
            }
        }
        &.active{
            i.open{
                opacity: 0;
                pointer-events: none;
            }
            i.close{
                opacity: 1;
                pointer-events: auto;
            }
        }
        
    }

}

button{
    padding: 10px 20px;
    border-radius: 21px;
    background: #fff;
    font-weight: bold;
    cursor: pointer;
    border: solid 2px #000;
    transition: .25s all ease-in-out;
    transform: scale(1);
    &:hover{
        transform: scale(1.1);
    }
}

.subTitle{
    font-size: 1.2em;
}

.emo{
    font-size: 25px;
    @media screen and(min-width: 480px){

    }
    @media screen and(min-width: 1900px){
        font-size: 1vw;
    }
}