.contactWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 50vh;
  padding: 20px;
}

@media screen and (min-width: 1020px) {
  .contactWrapper {
    height: 100vh;
    padding: 0;
  }
}

.contactWrapper form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
}

.contactWrapper form input {
  border: 2px solid #000;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  outline: none;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media screen and (min-width: 700px) {
  .contactWrapper form input {
    width: calc(50% - 10px);
  }
}

.contactWrapper form textarea {
  width: 100%;
  min-height: 100px;
  border: 2px solid #000;
  outline: none;
  padding: 10px;
}

.contactWrapper form button {
  background: #000;
  color: #fff;
  border: none;
  margin: 20px 0;
  margin-right: 20px;
  font-size: 1vw;
  font-weight: bold;
}
