.introWrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: visible;
    min-height: 70vh;
    @media screen and (min-width: 1020px){
        height: 100vh;
    }
    article{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3vw 20vw;
        border-radius: 60%;
        background: #f5f5f569;
        z-index: 99;
        margin-top: 10vh;
        @media screen and (min-width: 1020px){
            height: 100vh;
            margin-top: 0;
            padding: 3vw 19vw;

        }        
        .intro{
            font-size: 16px;
            @media screen and (min-width: 720px){
                font-size: 20px;
            }
        }
        .title{
            position: relatiive;
            z-index: 9;
            font-size: 14vw;
            br{
                display: none;
            }
            @media screen and (min-width: 720px){
                font-size: 5vw;
                br{
                    display: block;
                }
            }            
        }
        .copy{
            display:  none;
        }        
    }

    .bg{
        position: fixed;
        top: -150vh;
        left: -50vw;
        width: 200vw;
        height: 200vw;
        opacity: 0;
        background: rgb(255,255,255);

        border-radius: 50%;
        animation: move 40s ease infinite alternate;
        transition: .5s opacity ease-in-out;
        transition: .5s animation ease-in-out;
        z-index: -1;
        pointer-events: none;


    }
    .bg2{
        position: fixed;
        bottom: 0;
        right: -50vw;
        width: 100vw;
        height: 100vw;
        border-radius: 50%;
        opacity: 0;
        background: rgb(255,255,255);
        // background: radial-gradient(circle, rgba(255,0,112,1) 1%, rgba(148,187,233,0) 44%);
        animation: move 30s ease infinite alternate;
        transition: .5s opacity ease-in-out;
        transition: .5s animation ease-in-out;
        z-index: -1;
        pointer-events: none;
    }
}




.i{
    display: flex;
    height: 100vh;
}

.i-left{
    flex: 1;
}

.i-right{
    flex: 1;
}

.i-left-wrapper{
    padding: 50px;
}

.i-intro{
    font-size: 30px;
    font-weight: 300;
}

.i-name{
    font-size: 60px;
}

.i-title{
    height: 50px;
    overflow: hidden;
}

.i-title-wrapper{
    height: 100%;
    animation: move 20s ease-in-out infinite alternate;
}

@keyframes move {
 20%{
    opacity: 1;
    background: radial-gradient(circle, #1866af 1%, rgba(148, 187, 233, 0) 44%); 
}
45%{
    opacity: 0;
    background: rgb(255,0,112);
    background: radial-gradient(circle, rgba(255,0,112,1) 1%, rgba(148,187,233,0) 44%);    
 }
 50%{
    transition: .25s all ease;
    opacity: 1;
    background: rgb(255,0,112);
    background: radial-gradient(circle, rgba(255,0,112,1) 1%, rgba(148,187,233,0) 44%);    
 }
 75%{
    background: radial-gradient(circle, #1866af 1%, rgba(148, 187, 233, 0) 44%); 
    opacity: 1;

 }
 100%{
    background: radial-gradient(circle, #181baf 1%, rgba(148, 187, 233, 0) 44%);
    opacity: .5;

 }
}

.i-title-item{
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    /* color: red; */
    display: flex;
    align-items: center;
}